import { LegalEntityFormValues, ProfileType } from '@dltru/dfa-models'
import {
    FinancialPositionStep,
    IRoleFormStepProps,
    IStepper,
    LegalEntityStep1,
    LegalEntityStep7,
    StepperC,
} from '@dltru/dfa-ui'
import { FC } from 'react'

import { UploadInput } from '@components/Form'

import { BankDetailsStep } from '../components/BankDetailsStep'
import { BusinessReputationStep } from '../components/BusinessReputationStep'
import { FinancialOperationsStep } from '../components/FinancialOperationsStep'
import { LicensesStep } from '../components/LicensesStep'
import { Step2, Step6, Step8, Step11 } from './steps'
import api from '@services/api'

export const LegalEntityStepper: FC<
    Omit<IStepper<LegalEntityFormValues>, 'children'> & IRoleFormStepProps
> = ({ isEditAllow, disableFields, ...props }) => (
    <StepperC outOfValuesMerge={[7]} {...props}>
        <LegalEntityStep1 isEditAllow={isEditAllow} disableFields={disableFields} />
        <Step2 isEditAllow={isEditAllow} />
        <LicensesStep stepIndex={2} stepTitle="Сведения о лицензиях" isEditAllow={isEditAllow} />
        <FinancialPositionStep stepIndex={3} isEditAllow={isEditAllow} UploadInput={UploadInput} />
        <BusinessReputationStep stepIndex={4} isEditAllow={isEditAllow} />
        <Step6 isEditAllow={isEditAllow} />
        <LegalEntityStep7 isEditAllow={isEditAllow} api={api} />
        <Step8 isEditAllow={isEditAllow} />
        <BankDetailsStep stepIndex={8} isEditAllow={isEditAllow} />
        <FinancialOperationsStep stepIndex={9} isEditAllow={isEditAllow} profileType={ProfileType.LEGL} />
        <Step11 isEditAllow={isEditAllow} />
    </StepperC>
)
