import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import { getAnketaTagProps } from '@resources/anketaState'

import {
    HasBeneficiary,
    LegalEntityFormValues,
    ProfileStatus,
    ProfileType,
    editableProfileStatuses,
    legalEntityForUpdateData,
    legalEntityFrontToBack,
} from '@dltru/dfa-models'
import {
    Box,
    CopyButton,
    IFooter,
    PageHeader,
    Tag,
    checkBeneficialUuids,
    getFullName,
} from '@dltru/dfa-ui'

import { profileDetailsSlice } from '@store/profiles/details'
import { profileSelector } from '@store/profiles/details/selectors'
import { roleFormsSlice } from '@store/roleForms'
import { roleFormSelector } from '@store/roleForms/selectors'

import { countOfSteps } from '@components/Layout/RoleFormsLayout'

import { AlertForAnketa } from '../components/AlertForAnketa'
import { Footer } from '../components/Footer'

import { LegalEntityStepper } from './LegalEntityStepper'
import { disableFields } from './resources'

export const LegalEntity: FC = () => {
    const reduxDispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const state = location.state as { isCreate: boolean } | undefined
    const isCreate = state?.isCreate
    const initialData = useSelector(roleFormSelector.selectLegalEntityFormValue)
    const profile = useSelector(roleFormSelector.selectLegalEntityAnketa)
    const approvalHistory = useSelector(profileSelector.selectApprovalHistory)
    const name = !isCreate && initialData ? getFullName(profile) : 'Юридическое лицо'
    const [footerStatus, setFooterStatus] = useState<IFooter['status']>('initial')

    const onStepperFinish = (values: LegalEntityFormValues) => {
        if (isCreate) {
            reduxDispatch(roleFormsSlice.actions.clearOldProfile())
            reduxDispatch(
                roleFormsSlice.actions.updateLegalEntityAnketa(legalEntityFrontToBack(values)),
            )
            setFooterStatus('ready-to-new')
        } else if (profile) {
            const dataForSend = legalEntityForUpdateData(values, profile)
            reduxDispatch(roleFormsSlice.actions.putLegalEntityProfile(dataForSend))
        }
    }

    useEffect(() => {
        if (
            !isCreate &&
            (profile?.profile_status === ProfileStatus.REJECTED ||
                profile?.profile_status === ProfileStatus.REWORK) &&
            approvalHistory.length === 0
        ) {
            reduxDispatch(profileDetailsSlice.actions.getApprovalHistory())
        }
    }, [profile?.profile_status])

    useEffect(() => {
        if (
            initialData?.hasBeneficiary === HasBeneficiary.YES &&
            profile?.beneficial_owners_uuids
        ) {
            reduxDispatch(roleFormsSlice.actions.getBeneficiaries(profile.beneficial_owners_uuids))
        }
    }, [JSON.stringify(profile?.beneficial_owners_uuids)])

    if (
        !isCreate &&
        (!initialData ||
            (initialData.hasBeneficiary === HasBeneficiary.YES &&
                !checkBeneficialUuids(
                    profile?.beneficial_owners_uuids,
                    initialData.beneficial_owners_uuids,
                )))
    ) {
        return null
    }

    const tagProps =
        !isCreate && profile?.profile_status ? getAnketaTagProps(profile.profile_status) : undefined

    const isEditAllow =
        !initialData?.id ||
        (profile?.profile_status !== undefined &&
            editableProfileStatuses.includes(profile.profile_status))

    return (
        <>
            <Box padding={16}>
                <PageHeader
                    title={`Анкета клиента: ${name}`}
                    tags={tagProps ? <Tag color={tagProps.color}>{tagProps.title}</Tag> : undefined}
                    onBack={() => navigate(-1)}
                    subTitle={
                        !isCreate && profile?.user_uuid ? (
                            <>
                                ID {profile.user_uuid}&ensp;
                                <CopyButton text={profile.user_uuid} />
                            </>
                        ) : undefined
                    }
                />
            </Box>
            {!isCreate && <AlertForAnketa approvalHistory={approvalHistory} />}
            <LegalEntityStepper
                initialValues={
                    (!isCreate || !initialData?.id) && initialData
                        ? initialData
                        : ({} as LegalEntityFormValues)
                }
                onStepperFinish={onStepperFinish}
                initialStep={!isCreate && initialData?.id ? countOfSteps[ProfileType.LEGL] + 1 : 0}
                isEditAllow={isEditAllow}
                disableFields={
                    profile?.profile_status === ProfileStatus.REWORK ? disableFields : undefined
                }
            />

            <Footer
                status={footerStatus}
                setFooterStatus={setFooterStatus}
                profileType={ProfileType.LEGL}
            />
        </>
    )
}
