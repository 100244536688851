import {
    CardDivider,
    Form,
    IRoleFormStepProps,
    Step,
    StepperContext,
    useEditable,
} from '@dltru/dfa-ui'
import { FC, useContext } from 'react'

import { UploadInput } from '@components/Form'

const STEP_INDEX = 10

export const Step11: FC<IRoleFormStepProps> = ({ isEditAllow }) => {
    const [form] = Form.useForm()
    const { editable, stepsTotal, currentStep } = useContext(StepperContext)
    const isEdit = useEditable(currentStep, STEP_INDEX, editable)
    const allowEdit =
        currentStep >= stepsTotal &&
        (!editable.length || editable.includes(STEP_INDEX)) &&
        isEditAllow

    return (
        <Step
            form={form}
            stepIndex={STEP_INDEX}
            stepTitle="Дополнительная информация о юридическом лице"
            allowEdit={allowEdit}
            saveAfterEdit
        >
            <div className="formRow">
                <UploadInput
                    className="formComponentItem"
                    name="state_registration_file_uuids"
                    label="Справка о гос.регистрации"
                    uneditable={isEdit}
                    required
                    labelOnlyUneditable={false}
                    title="Загрузите фотографии документа. Допустимый формат файлов pdf, png, jpeg, jpg, tiff. Общий вес не более 5 МБ"
                    maxSizeMb={5}
                    accept=".pdf,.png,.jpeg, .jpg, .tiff"
                />
            </div>
            <CardDivider />
            <div className="formRow">
                <UploadInput
                    className="formComponentItem"
                    name="constituent_documents_file_uuids"
                    label="Учредительные документы"
                    title="Загрузите фотографии документа. Допустимый формат файлов pdf, png, jpeg, jpg, tiff. Общий вес не более 5 МБ"
                    maxSizeMb={5}
                    accept=".pdf,.png,.jpeg, .jpg, .tiff"
                    uneditable={isEdit}
                    required
                    labelOnlyUneditable={false}
                />
            </div>
            <CardDivider />

            <div className="formRow">
                <UploadInput
                    className="formComponentItem"
                    name="rent_premise_file_uuids"
                    label="Договор аренды нежилого помещения"
                    title="Загрузите фотографии документа. Допустимый формат файлов pdf, png, jpeg, jpg, tiff. Общий вес не более 5 МБ"
                    maxSizeMb={5}
                    accept=".pdf,.png,.jpeg, .jpg, .tiff"
                    uneditable={isEdit}
                    required
                    labelOnlyUneditable={false}
                />
            </div>
        </Step>
    )
}
